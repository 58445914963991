/*
https://tailwindcss.com/docs/adding-custom-styles#adding-base-styles
*/
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -o-font-smoothing: antialiased;
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url(/static/global/fonts/inter-var-latin-ext.woff2) format("woff2");
  }
  body,
  html,
  #__next {
    @apply w-full h-full;
  }
  body {
    @apply bg-neutral-100
    text-neutral-700;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    @apply font-bold
    text-neutral-950;
  }
  h1,
  .h1 {
    @apply text-3xl md:text-4xl;
  }
  h2,
  .h2 {
    @apply text-2xl md:text-3xl;
  }
  h3,
  .h3 {
    @apply text-xl md:text-2xl;
  }
  h4,
  .h4 {
    @apply text-xl;
  }
  h5,
  .h5 {
    @apply text-lg;
  }
  h6,
  .h6 {
    @apply text-sm
    uppercase tracking-wider;
  }
  hr {
    @apply m-0 w-full h-px border-0 bg-neutral-200;
    &.vertical {
      @apply w-px;
    }
  }
  ul {
    @apply pl-4;
    li {
      @apply list-disc list-outside p-0;
    }
  }
  a {
    @apply transition-all ease-in-out text-primary-700 font-medium;
    &:hover {
      @apply underline;
    }
  }
  .fake-button {
    @apply inline-flex ease-in-out
    cursor-pointer
    items-center
    justify-center
    whitespace-nowrap
    font-semibold
    rounded-xl
    ring-offset-white
    transition-colors
    hover:no-underline
    focus-visible:outline-none
    focus-visible:ring-2
    focus-visible:ring-neutral-950
    focus-visible:ring-offset-2
    disabled:pointer-events-none
    disabled:opacity-50
    h-10 px-4 py-2
    bg-primary-600
    text-white
    hover:bg-primary-700
    active:bg-primary-900/90;
    &.outlined {
      @apply text-neutral-700
      border
      border-slate-200
      bg-white
      hover:bg-slate-100
      hover:text-slate-900
      active:bg-slate-200
      shadow-sm;
    }
    &.large {
      @apply h-12 px-6 text-lg;
    }
    &.small {
      @apply h-8 px-3 text-sm;
    }
    &.icon {
      @apply h-10 w-10 p-0;
    }
    &.icon-small {
      @apply h-8 w-8 p-0;
    }
  }
  .sidebar-link {
    @apply no-underline
    w-full
    md:w-12
    md:h-12
    p-4 md:p-0
    text-neutral-500
    flex
    relative
    items-center
    justify-center
    gap-2 font-normal
    border-l-4 border-[transparent] md:border-none
    md:rounded-lg;
    &:hover {
      @apply no-underline bg-neutral-50 text-neutral-600;
    }
    p {
      @apply md:hidden flex-1 text-left;
    }
    .nav-icon {
      @apply w-6 h-6 flex items-center justify-center;
    }
    &.selected {
      @apply text-neutral-950 md:text-white
      font-semibold
      border-primary-500
      bg-neutral-100 md:bg-primary-600;
    }
    &.account {
      @apply md:w-full md:h-auto md:p-3 md:mb-1 font-normal;
      p {
        @apply md:flex;
      }
      .nav-icon {
        @apply md:w-5 md:h-5;
      }
      &.selected {
        @apply md:text-neutral-950 md:border-[transparent] md:bg-neutral-100;
      }
    }
  }
  .sidebar-link-account {
    @apply no-underline bg-neutral-50 text-neutral-600;
    &:hover {
      @apply no-underline bg-neutral-100 text-neutral-900;
    }
  }
}

@layer components {
  .help-accordion {
    @apply px-4 py-1.5 bg-white;
    .accordion-item {
      @apply border-none;
    }
    .accordion-trigger {
      @apply py-2.5 font-medium text-neutral-500;
    }
    .accordion-content {
      @apply flex flex-col pb-2;
    }
    .help-link {
      @apply text-sm
      rounded-lg
      px-4 py-2
      font-normal
      text-neutral-700
      bg-white
      hover:no-underline
      hover:bg-neutral-50
      hover:text-neutral-900;
      &.selected {
        @apply bg-neutral-100 text-neutral-950 font-medium;
      }
    }
    [data-state="open"] {
      .accordion-trigger {
        @apply text-neutral-800;
      }
    }
  }
  .progress-bar {
    @apply w-full
    rounded-full
    overflow-hidden
    h-2;
    &[value]::-webkit-progress-bar {
      @apply bg-neutral-200;
    }
    &[value]::-webkit-progress-value {
      @apply bg-primary-600;
    }
  }
  .form-group {
    @apply mb-4;
  }
  .form-label {
    @apply block
    font-medium
    text-neutral-900
    mb-1;
  }
  .form-group-inline {
    @apply flex
    flex-col md:flex-row
    mt-4 md:mt-0
    md:py-4
    items-start md:gap-4
    md:border-b border-neutral-200;
    .form-label {
      @apply min-w-40 w-full md:w-60;
    }
    .form-element {
      @apply w-full md:max-w-md;
    }
    .inline-form-group {
      @apply flex flex-col
      w-full md:max-w-md gap-4;
    }
  }
  .label-description {
    @apply text-xs mt-0.5 text-neutral-500 font-normal;
  }
  .form-error {
    @apply text-xs mt-1 text-error-600 font-medium;
  }
  .form-success {
    @apply text-xs mt-1 text-success-700 font-medium;
  }
  .form-helper {
    @apply text-xs mt-1 text-neutral-500;
  }
  .form-input {
    @apply block w-full
    transition-all
    ease-in-out
    rounded-md
    border-0
    py-2
    px-4
    text-neutral-700
    ring-1
    ring-inset
    ring-neutral-200
    placeholder:text-neutral-400
    focus:ring-2
    focus:ring-inset
    focus:ring-neutral-600;
    &:disabled {
      @apply opacity-70 cursor-not-allowed bg-neutral-50;
    }
    &.large {
      @apply py-2.5 text-lg;
    }
    &.has-error {
      @apply ring-error-300;
    }
  }
  .form-checkbox {
    @apply rounded
    transition-all
    ease-in-out
    border-neutral-300
    bg-white
    text-primary-600
    focus:ring-neutral-600;
    &.large {
      @apply w-5 h-5;
    }
  }
  .form-radio {
    @apply rounded-full
    transition-all
    ease-in-out
    border-neutral-300
    bg-white
    text-primary-600
    focus:ring-neutral-600;
    &.large {
      @apply w-5 h-5;
    }
    &.sm {
      @apply w-3 h-3;
    }
  }
  .form-option-card {
    @apply flex
    relative
    gap-3
    px-3
    py-2
    min-h-14
    rounded-md
    bg-neutral-50
    border
    cursor-pointer
    border-neutral-200
    items-center
    transition-all
    mb-2
    overflow-hidden
    ease-in-out;
    &:after {
      @apply absolute
      z-0
      w-full
      h-full
      top-0 left-0 right-0 bottom-0;
      content: "";
    }
    &:hover {
      @apply border-neutral-300
      shadow-sm;
    }
    &.active {
      @apply bg-primary-50;
    }
    p,
    .form-checkbox,
    .form-radio {
      @apply relative z-10;
    }
    .hidden-option-active-indicator {
      @apply absolute
      z-0
      w-full
      h-full
      top-0 left-0 right-0 bottom-0;
    }
    .form-checkbox:checked + .hidden-option-active-indicator,
    .form-radio:checked + .hidden-option-active-indicator {
      @apply bg-white;
    }
  }
  .available-hour {
    @apply flex
    relative
    gap-1.5 p-2
    rounded-md
    bg-primary-100
    cursor-pointer
    items-center
    justify-center
    transition-all
    text-xs
    font-bold
    w-full
    text-primary-950
    ease-in-out;
    &:hover {
      @apply bg-primary-200;
    }
    &.selected {
      @apply bg-primary-300 border border-primary-950 border-dashed text-primary-950;
    }
  }
  .card {
    @apply flex
    items-center
    flex-col
    rounded-xl
    shadow-md
    px-6 md:px-10
    py-6
    bg-white;
  }
  table {
    @apply text-sm border-separate border-spacing-0 rounded-lg border border-neutral-200 overflow-hidden;
    th {
      @apply text-left
      uppercase
      text-xs
      tracking-wide
      bg-neutral-100
      p-3;
    }
    td {
      @apply p-3 border-t border-neutral-200;
    }
    tr {
      @apply transition-all;
      &:hover {
        @apply bg-neutral-50;
      }
    }
  }
  .tabs-wrapper {
    @apply border-b-2 border-neutral-200 flex gap-4;
    .tab-link {
      @apply border-b-2 border-[transparent] mb-[-2px] py-3 inline-flex text-neutral-500 hover:text-neutral-950 hover:no-underline;
      &.selected {
        @apply border-primary-600 text-primary-700;
      }
    }
    &.rounded {
      @apply rounded-lg bg-neutral-100 text-sm p-1 flex items-start;
      .tab-link {
        @apply rounded-lg px-3 py-2 font-semibold text-neutral-500 hover:text-neutral-950 hover:no-underline border border-[transparent] text-center;
        &.selected {
          @apply bg-white text-neutral-950 border-neutral-200 shadow-sm;
        }
      }
    }
  }
}
